<template>
  <ContentWrapper>
    <breadcrumb/>
    <div class="container animated-fast fadeInDown">

      <markdown-parser :source="this.$t('growing.view.market.terms')"></markdown-parser>

      <br/>

      <b-btn :to="{name: 'Growing:Market'}" variant="secondary" class="mt-3">{{ $t('common.button.back.label') }}</b-btn>

    </div>
  </ContentWrapper>
</template>
<script>
import MarkdownParser from "@/components/Common/MarkdownParser";
export default {
  components: {MarkdownParser}
}
</script>